<template>
  <ion-menu content-id="main-content">
    <ion-content>
      <ion-list lines="none">
        <ion-list-header>
          <img src="@/assets/img/logo-login.svg" alt="Agrocontracion" />
        </ion-list-header>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle
          v-for="p in animalsPages"
          :key="p.title"
          :auto-hide="false"
        >
          <ion-item v-if="p.show" button @click="navigate(p.url)">
            <i class="text-3xl text-primary-500" :class="p.icon"></i>
            <ion-label class="ml-4">
              {{ p.title }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle
          v-for="p in contractsPages"
          :key="p.title"
          :auto-hide="false"
        >
          <ion-item v-if="p.show" button @click="navigate(p.url)">
            <i class="text-3xl text-primary-500" :class="p.icon"></i>
            <ion-label class="ml-4">
              {{ p.title }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle
          v-for="p in feriaPages"
          :key="p.title"
          :auto-hide="false"
        >
          <ion-item v-if="p.show" button @click="navigate(p.url)">
            <i class="text-3xl text-primary-500" :class="p.icon"></i>
            <ion-label class="ml-4">
              {{ p.title }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/operador/perfil')">
            <i class="i-[prime--user] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Mi Cuenta </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/operador/configuracion')">
            <i class="i-[prime--cog] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Configuración </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/operador/support')">
            <i class="i-[prime--question-circle] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Ayuda </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/operador/contact')">
            <i class="i-[prime--phone] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Contacto </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="handleLogout()">
            <i class="i-[prime--sign-out] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Cerrar Sesión </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script setup lang="ts">
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  useIonRouter,
} from "@ionic/vue";

const router = useIonRouter();
const userStore = useUserStore();
const authStore = useAuthStore();

const navigate = (url: string) => {
  router.push(url);
};

const handleLogout = async () => {
  authStore.logout();
};

onMounted(async () => {
  await userStore.checkAndFetchUser();
  await useTemplateStore().fetchTemplates();
});

const animalsPages = [
  {
    title: "Animales",
    url: "/es/operador/animales",
    icon: "i-[healthicons--animal-cow-outline]",
    show: true,
  },
];

const contractsPages = [
  {
    title: "Contratos Online",
    url: "/es/operador/contratos",
    icon: "i-[mdi--file-sign]",
    show: true,
  },
  {
    title: "Nuevo Contrato Online",
    url: "/es/operador/contratos/nuevo",
    icon: "i-[mdi--file-plus-outline]",
    show: true,
  },
  {
    title: "Contratos Offline",
    url: "/es/operador/contratos/offline",
    icon: "i-[mdi--file-lock]",
    show: true,
  },
  {
    title: "Agenda de Ganaderos",
    icon: "i-[mdi--notebook]",
    url: "/es/operador/contratos/offline/ranchers",
    show: true,
  },
  {
    title: "Nuevo Contrato Offline",
    icon: "i-[mdi--file-plus]",
    url: "/es/operador/contratos/offline/new",
    show: true,
  },
  {
    title: "Plantillas",
    url: "/es/operador/contratos/templates",
    icon: "i-[tdesign--template]",
    show: true,
  },
  {
    title: "Tarifas",
    url: "/es/operador/contratos/rates",
    icon: "i-[mdi--currency-eur]",
    show: true,
  },
  {
    title: "Técnicos de campo",
    icon: "i-[mdi--account-hard-hat]",
    url: "/es/operador/contratos/technicians",
    show: true,
  },
];

const feriaPages = [
  {
    title: "Feria Virtual",
    url: "/es/operador/feria",
    icon: "i-[mdi--shopping]",
    show: true,
  },
  {
    title: "Mis Propuestas",
    url: "/es/operador/feria/propuestas/mis-propuestas",
    icon: "i-[mdi--cart]",
    show: true,
  },
];
</script>
