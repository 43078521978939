<template>
  <ion-menu content-id="main-content">
    <ion-content>
      <ion-list lines="none">
        <ion-list-header>
          <img src="@/assets/img/logo-login.svg" alt="Agrocontracion" />
        </ion-list-header>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle
          v-for="p in animalsPages"
          :key="p.title"
          :auto-hide="false"
        >
          <ion-item v-if="p.show" button @click="navigate(p.url)">
            <i class="text-3xl text-primary-500" :class="p.icon"></i>
            <ion-label class="ml-4">
              {{ p.title }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle
          v-for="p in contractsPages"
          :key="p.title"
          :auto-hide="false"
        >
          <ion-item v-if="p.show" button @click="navigate(p.url)">
            <i class="text-3xl text-primary-500" :class="p.icon"></i>
            <ion-label class="ml-4">
              {{ p.title }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle
          v-for="p in feriaPages"
          :key="p.title"
          :auto-hide="false"
        >
          <ion-item v-if="p.show" button @click="navigate(p.url)">
            <i class="text-3xl text-primary-500" :class="p.icon"></i>
            <ion-label class="ml-4">
              {{ p.title }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/perfil')">
            <i class="i-[prime--user] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Mi Cuenta </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/mis-explotaciones')">
            <i class="i-[mdi--home-group] text-3xl text-primary-500"></i>
            <ion-label class="ml-4">Mis Explotaciones</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/configuracion')">
            <i class="i-[prime--cog] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Configuración </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/support')">
            <i class="i-[prime--question-circle] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Ayuda </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/contact')">
            <i class="i-[prime--phone] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Contacto </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="handleLogout()">
            <i class="i-[prime--sign-out] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Cerrar Sesión </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script setup lang="ts">
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from "@ionic/vue";

const userStore = useUserStore();
const templateStore = useTemplateStore();
const router = useIonRouter();
const authStore = useAuthStore();

const navigate = (url: string) => {
  router.push(url);
};

const handleLogout = async () => {
  authStore.logout();
};

onMounted(async () => {
  await userStore.fetchUser();
  await templateStore.fetchTemplates();
});

const animalsPages = computed(() => [
  {
    title: "Animales en Propiedad",
    url: "/es/ganadero/animales/en-propiedad",
    icon: "i-[healthicons--animal-cow-outline]",
    show: true,
  },
  {
    title: "Animales Contrato Venta",
    url: "/es/ganadero/animales/contrato-venta",
    icon: "i-[healthicons--animal-cow-outline]",
    show: true,
  },
  {
    title: "Animales Contrato Compra",
    url: "/es/ganadero/animales/contrato-compra",
    icon: "i-[healthicons--animal-cow-outline]",
    show: userStore.isRancherContractAllowed,
  },
  {
    title: "Nuevo Animal",
    url: "/es/ganadero/animales/nuevo",
    icon: "i-[mdi--plus-box-outline]",
    show: true,
  },
  {
    title: "Nuevo Listado Animales",
    url: "/es/ganadero/animales/nuevo-listado",
    icon: "i-[mdi--upload]",
    show: true,
  },
]);

const contractsPages = computed(() => [
  {
    title: "Contratos Venta",
    url: "/es/ganadero/contratos",
    icon: "i-[mdi--file-sign]",
    show: true,
  },
  {
    title: "Contratos Compra",
    url: "/es/ganadero/contratos/compra",
    icon: "i-[mdi--file-sign]",
    show: userStore.isRancherContractAllowed,
  },
  {
    title: "Nuevo Contrato",
    url: "/es/ganadero/contratos/nuevo",
    icon: "i-[mdi--file-plus-outline]",
    show: userStore.isRancherContractAllowed,
  },
  {
    title: "Plantillas",
    url: "/es/ganadero/contratos/templates",
    icon: "i-[tdesign--template]",
    show: userStore.isRancherContractAllowed,
  },
  {
    title: "Tarifas",
    url: "/es/ganadero/contratos/rates",
    icon: "i-[mdi--currency-eur]",
    show: userStore.isRancherContractAllowed,
  },
]);

const feriaPages = computed(() => [
  {
    title: "Feria Virtual",
    url: "/es/ganadero/feria",
    icon: "i-[mdi--shopping]",
    show: true,
  },
  {
    title: "Nuevo Anuncio",
    url: "/es/ganadero/feria/nuevo-anuncio",
    icon: "i-[mdi--plus-box-outline]",
    show: true,
  },
  {
    title: "Mis Anuncios",
    url: "/es/ganadero/feria/mis-anuncios",
    icon: "i-[tabler--list-details]",
    show: true,
  },
  {
    title: "Mis Propuestas",
    url: "/es/ganadero/feria/propuestas/mis-propuestas",
    icon: "i-[mdi--cart]",
    show: userStore.isRancherContractAllowed,
  },
]);
</script>
