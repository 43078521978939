import { useIonRouter } from "@ionic/vue";
import { defineStore, getActivePinia } from "pinia";
import { computed, ref } from "vue";

import { useFirebaseMessaging } from "@/composables/useFirebaseMessaging";
import authService from "@/core/services/auth.service";
import i18n from "@/plugins/i18n";

const getDefaultState = () => ({
  stateOneTimePassword: null,
  profileAgent: null,
  cognitoCredentials: {
    accessToken: null,
    refreshToken: null,
    idToken: null,
    expiresIn: null,
    tokenType: null,
  },
  refreshResponse: {},
});

export const useAuthStore = defineStore(
  "authStore",
  () => {
    const router = useIonRouter();
    const { deleteDeviceUuid } = useFirebaseMessaging();

    // State
    const stateOneTimePassword = ref(null);
    const profileAgent = ref(null);
    const cognitoCredentials = ref({
      accessToken: null,
      refreshToken: null,
      idToken: null,
      expiresIn: null,
      tokenType: null,
    });
    const refreshResponse = ref({});

    // Getters
    const isLoggedIn = computed(() => {
      return cognitoCredentials.value.accessToken !== null;
    });

    async function login({ username, password }) {
      try {
        const cognitoAuthResponse = await authService.cognitoAuth({
          username,
          password,
        });

        cognitoCredentials.value.accessToken = cognitoAuthResponse.AccessToken;
        cognitoCredentials.value.refreshToken =
          cognitoAuthResponse.RefreshToken;
        cognitoCredentials.value.idToken = cognitoAuthResponse.IdToken;
        cognitoCredentials.value.expiresIn = cognitoAuthResponse.ExpiresIn;
        cognitoCredentials.value.tokenType = cognitoAuthResponse.TokenType;

        return cognitoAuthResponse;
      } catch (error) {
        console.error("Error during authentication:", error);
        throw error;
      }
    }

    async function logout() {
      try {
        await deleteDeviceUuid();
      } catch (error) {
        console.error("Error deleting device uuid:", error);
      }

      await authService.logoutCognito();

      // map through that list and use the **$reset** fn to reset the state
      await getActivePinia()._s.forEach((store) => store.$reset());

      try {
        await localStorage.clear();
      } catch (error) {
        console.error("Error cleaning local storage:", error);
      }

      router.push("/es/login");
    }

    async function logoutAfterNewtorkError() {
      await authService.logoutCognito();

      // map through that list and use the **$reset** fn to reset the state
      try {
        await getActivePinia()._s.forEach((store) => store.$reset());
      } catch (error) {
        console.error("Error cleaning local storage:", error);
      }

      try {
        await localStorage.clear();
      } catch (error) {
        console.error("Error cleaning local storage:", error);
      }

      router.push("/es/login");
    }

    async function refreshToken() {
      try {
        const cognitoRefreshResponse = await authService.cognitoRefreshToken({
          refreshToken: cognitoCredentials.value.refreshToken,
        });

        cognitoCredentials.value.accessToken =
          cognitoRefreshResponse.AccessToken;
        cognitoCredentials.value.idToken = cognitoRefreshResponse.IdToken;
        cognitoCredentials.value.expiresIn = cognitoRefreshResponse.ExpiresIn;
        cognitoCredentials.value.tokenType = cognitoRefreshResponse.TokenType;

        // Preserve the existing refresh token if it is not returned in the response
        if (cognitoRefreshResponse.RefreshToken) {
          cognitoCredentials.value.refreshToken =
            cognitoRefreshResponse.RefreshToken;
        }
        refreshResponse.value = cognitoRefreshResponse;

        return cognitoRefreshResponse;
      } catch (error) {
        console.error("Error during token refresh:", error);
        throw error;
      }
    }

    async function checkOneTimePass() {
      try {
        //api call for checking one time password
        //dispatch("agente/checkOneTimePassword", _token)
        const _isOneTimePassword = stateOneTimePassword.value;
        if (_isOneTimePassword === true) {
          router.push({
            name: "perfil-technician",
            params: { lang: i18n.global.locale, firstLogin: "true" },
          });
        } else {
          router.push({
            name: "contratos-technician",
            params: { lang: i18n.global.locale },
          });
        }
        return _isOneTimePassword;
      } catch (error) {
        console.error(error);
      }
    }

    function $reset() {
      const defaultState = getDefaultState();

      stateOneTimePassword.value = defaultState.stateOneTimePassword;
      profileAgent.value = defaultState.profileAgent;
      cognitoCredentials.value = defaultState.cognitoCredentials;
      refreshResponse.value = defaultState.refreshResponse;
    }

    return {
      // State
      stateOneTimePassword,
      profileAgent,
      cognitoCredentials,
      refreshResponse,
      // Getters
      isLoggedIn,
      // Actions
      login,
      logout,
      logoutAfterNewtorkError,
      refreshToken,
      checkOneTimePass,
      $reset,
    };
  },
  {
    persist: true, // Enable persistence for the entire store
  },
);
