<template>
  <ion-menu content-id="main-content">
    <ion-content>
      <ion-list lines="none">
        <ion-list-header>
          <img src="@/assets/img/logo-login.svg" alt="Agrocontracion" />
        </ion-list-header>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle
          v-for="p in animalsPages"
          :key="p.title"
          :auto-hide="false"
        >
          <ion-item v-if="p.show" button @click="navigate(p.url)">
            <i class="text-3xl text-primary-500" :class="p.icon"></i>
            <ion-label class="ml-4">
              {{ p.title }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle
          v-for="p in contractsPages"
          :key="p.title"
          :auto-hide="false"
        >
          <ion-item v-if="p.show" button @click="navigate(p.url)">
            <i class="text-3xl text-primary-500" :class="p.icon"></i>
            <ion-label class="ml-4">
              {{ p.title }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle
          v-for="p in feriaPages"
          :key="p.title"
          :auto-hide="false"
        >
          <ion-item v-if="p.show" button @click="navigate(p.url)">
            <i class="text-3xl text-primary-500" :class="p.icon"></i>
            <ion-label class="ml-4">
              {{ p.title }}
            </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
      <Divider />
      <ion-list lines="none">
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/perfil')">
            <i class="i-[prime--user] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Mi Cuenta </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/mis-explotaciones')">
            <i class="i-[mdi--home-group] text-3xl text-primary-500"></i>
            <ion-label class="ml-4">Mis Explotaciones</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/configuracion')">
            <i class="i-[prime--cog] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Configuración </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/support')">
            <i class="i-[prime--question-circle] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Ayuda </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="navigate('/es/ganadero/contact')">
            <i class="i-[prime--phone] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Contacto </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle :auto-hide="false">
          <ion-item button @click="handleLogout()">
            <i class="i-[prime--sign-out] text-3xl text-primary-500"></i>
            <ion-label class="ml-4"> Cerrar Sesión </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script setup lang="ts">
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
} from "@ionic/vue";

const userStore = useUserStore();
const router = useIonRouter();
const authStore = useAuthStore();

const navigate = (url: string) => {
  router.push(url);
};

const handleLogout = async () => {
  authStore.logout();
};

onMounted(async () => {
  await userStore.checkAndFetchUser();
});

const animalsPages = [
  {
    title: "Animales en Propiedad",
    url: "/es/ganadero/animales/en-propiedad",
    icon: "i-[healthicons--animal-cow-outline]",
    show: true,
  },
  {
    title: "Animales Contrato Venta",
    url: "/es/ganadero/animales/contrato-venta",
    icon: "i-[healthicons--animal-cow-outline]",
    show: true,
  },
];

const contractsPages = [
  {
    title: "Contratos Venta",
    url: "/es/ganadero/contratos",
    icon: "i-[mdi--file-sign]",
    show: true,
  },
];

const feriaPages = [
  {
    title: "Feria Virtual",
    url: "/es/ganadero/feria",
    icon: "i-[mdi--shopping]",
    show: true,
  },
];
</script>
